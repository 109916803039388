<template>
  <vue-tel-input
    v-model="phone"
    mode="international"
    :defaultCountry="defaultCountry"
    :showDialCodeInList="true"
    :showDialCodeInSelection="true"
    :show-flags="true"
    :autoDefaultCountry="true"
    :showSearchBox="true"
    :validCharactersOnly="true"
    searchBoxPlaceholder="Search..."
    @validate="({ valid }) => (isValid = valid)"
    @blur="() => (blur = true)"
    class="form-input mt-2 w-full py1 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-gray-800 dark:border-gray-800 dark:focus:border-gray-800 focus:ring-0"
    :class="{
      'border-red-500': !isValid && blur,
    }"
  ></vue-tel-input>
</template>

<script setup>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const phone = ref('');
const isValid = ref(false);
const blur = ref(false);

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  defaultCountry: {
    type: String,
  },
});

// Use onMounted to ensure reactivity happens after the component is rendered
onMounted(() => {
  phone.value = props.modelValue;
});

watch(phone, (value) => {
  emit('update:modelValue', value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    phone.value = newValue;
  }
);
</script>

<style scss>
.vue-tel-input:focus-within {
  box-shadow: none !important;
}
</style>
